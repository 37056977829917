import { WithTranslation } from 'react-i18next';

export const formattedTotalBookedText = (
  totalBooked: number | undefined,
  t: WithTranslation['t']
): string => {
  if (!totalBooked) {
    return t('{{count}} people booked', { count: 0 });
  }
  if (totalBooked >= 10000) {
    return t('10,000+ people booked');
  }
  if (totalBooked >= 5000) {
    return t('5,000+ people booked');
  }
  if (totalBooked >= 1000) {
    return t('1,000+ people booked');
  }

  return t('{{count}} people booked', { count: totalBooked });
};
